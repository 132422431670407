<template>
  <AppModal v-model="model" :title="$t('ComponentEditPhoneModal.TitleModal').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #left>
          <AppBackButton
            name="Go Back"
            icon="<"
            analytics-name="edit-phone-modal-back"
            @click="dismiss"
          />
        </template>
        <h1>{{ $t('ComponentEditPhoneModal.Title').value }}</h1>
      </AppModalHeader>
    </template>

    <!-- Default slot -->
    <p class="phone-subtitle">
      {{ $t('ComponentEditPhoneModal.Description').value }}
    </p>

    <form id="editPhoneForm" @submit.prevent="onSubmitPhoneNumber">
      <AppInputPhone
        ref="appInputPhoneRef"
        v-model="phoneForm.phone"
        analytics-name="edit-phone-modal-input"
        :label="$t('ComponentEditPhoneModal.LabelPhoneNumber').value"
        :options="phoneCountries"
        :validation="validationPhoneNumber.phone"
        @areaCodeInputOption="onAreaCodeInputOption"
      />
    </form>
    <template #footer>
      <AppModalFooter>
        <AppButton
          :disabled="validationPhoneNumber.$anyInvalid"
          :loading="loading"
          type="submit"
          analytics-name="edit-phone-modal-continue"
          form="editPhoneForm"
        >
          {{ $t('ComponentEditPhoneModal.ButtonContinue').value }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { computed, reactive, toRef, watch } from '@vue/composition-api'
import { usePromiseLazy, useValidation } from 'vue-composable'

import { integer, required } from '@vuelidate/validators'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { storeToRefs } from 'pinia'
import { useRouter } from '@galileo/composables/useRouter'
import { otpDeliveryTypes } from '@galileo/api/ct/OTP/resource/_phoneNumber/post'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppInputPhone,
  AppBackButton,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAuthStore,
  useOtpStore,
  useCountriesStore,
  useProfileStore,
} from '@galileo/stores'

export default {
  name: 'EditPhoneModal',
  emits: ['input'],
  components: {
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppButton,
    AppInputPhone,
    AppBackButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const otpStore = useOtpStore()
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const countriesStore = useCountriesStore()
    const profileStore = useProfileStore()

    const profile = computed(() => authStore.userProfile)
    const { newPhoneNumber, flagProfilePhoneNumber } = storeToRefs(profileStore)

    const { model } = useVModel(props, emit)

    const phoneCountries = computed(() => countriesStore.offeredCountriesDialingCodesOptions)

    const initialPhoneNo = `${profile.value.homePhone.countryCode}${profile.value.homePhone.number}`

    const phoneForm = reactive({
      phone: initialPhoneNo,
      countryCode: profile.value.homePhone.countryCode.toString(),
      countryId: null,
    })

    const nameAbbr = computed(() => {
      const countries = phoneCountries.value.filter((item) =>
        phoneForm.countryId ? item.id === phoneForm.countryId : item.value === phoneForm.countryCode
      )
      return countries ? countries[0].nameAbbr : ''
    })

    const validationPhoneNumber = useValidation({
      phone: {
        $value: toRef(phoneForm, 'phone'),
        required: {
          $validator: required.$validator,
          $message: $t('ComponentEditPhoneModal.ValidationPhoneRequired').value,
        },
        integer: {
          $validator: integer.$validator,
          $message: $t('ComponentEditPhoneModal.ValidationPhoneNumerical').value,
        },
        isValidPhoneNumber: {
          $validator(v) {
            const parsePhoneNumberDetails = parsePhoneNumberFromString(v, nameAbbr.value)
            if (parsePhoneNumberDetails) {
              return parsePhoneNumberDetails.isValid()
            }
            return false
          },
          $message: $t('ComponentEditPhoneModal.ValidationPhoneValid').value,
        },
        phoneNumberSame: {
          $validator(v) {
            if (v.length > 0) {
              return v.replace('-') !== initialPhoneNo
            }
            return true
          },
          $message: ' ', // TODO - Add phone number same validation message
        },
      },
    })

    const formattedPhoneNumber = computed(() => {
      return phoneForm.phone.replace(phoneForm.countryCode, `${phoneForm.countryCode}-`)
    })

    // Reset api error if phone number updated
    watch(
      () => formattedPhoneNumber.value,
      (v) => {
        if (v.length > `${phoneForm.countryCode}-`.length) {
          if (otpCodeRequest.error) {
            otpCodeRequest.error = null
          }
        }
      }
    )

    const onAreaCodeInputOption = (v) => {
      phoneForm.countryCode = v.value
      phoneForm.countryId = v.id
    }

    const otpCodeRequest = reactive(
      usePromiseLazy(() =>
        otpStore.requestCode({
          referenceValue: formattedPhoneNumber.value,
          action: 90,
          deliveryType: otpDeliveryTypes.SMS,
        })
      )
    )

    const onSubmitPhoneNumber = async () => {
      if (validationPhoneNumber.$anyInvalid) {
        return
      }

      await otpCodeRequest.exec()

      //TODO: Error handling
      if (!otpCodeRequest.error) {
        const homePhone = {
          areaCode: phoneForm.phone.substring(1, 4),
          countryCode: phoneForm.countryCode,
          isValid: true,
          number: phoneForm.phone.replace(phoneForm.countryCode, ''),
          phoneNumberNoAreaCode: phoneForm.phone.substring(4, phoneForm.phone.length),
        }

        //TODO: Setting new number multiply times throws an API error
        newPhoneNumber.value = homePhone
        // Set flag in order to work with otp flow from profile account
        flagProfilePhoneNumber.value = true
        // Navigate to otp from profile account page
        router.push('/otp/edit-phone')
      }
    }

    return {
      model,
      phoneCountries,
      onSubmitPhoneNumber,
      onAreaCodeInputOption,
      validationPhoneNumber,
      phoneForm,
      $t,
      loading: computed(() => otpCodeRequest.loading),
    }
  },
}
</script>

<style scoped>
.phone-subtitle {
  @apply text-secondary-text type-subtitle pb-6;
}
</style>
